import '@mantine/core/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-datatable/styles.layer.css';
import 'mantine-contextmenu/styles.layer.css';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';

import { CookiesProvider } from 'react-cookie';
import { NavigationProgress } from '@mantine/nprogress';
import { notifications, Notifications } from '@mantine/notifications';
import { ContextMenuProvider } from 'mantine-contextmenu';
import { IconX } from '@tabler/icons-react';
import { Router } from './Router';
import './globals.css';
import { GlobalSpotlight } from '@/components/general/global-spotlight/GlobalSpotlight';
import { useErrorStore } from '@/state/errorStore';
import { ThemeProvider, useTheme } from '@/state/contexts/ThemeContext';
import { themes } from '@/config/themes.config';

const queryClient = new QueryClient();

function AppContent() {
  const { theme } = useTheme();

  const { error, clearError } = useErrorStore();
  useEffect(() => {
    if (error) {
      notifications.show({
        title: 'An error occurred',
        onClose: () => clearError(),
        message: error,
        color: 'red',
        icon: <IconX size="1.1rem" />,
      });
    }
  }, [error]);

  return (
    <MantineProvider theme={themes[theme]} forceColorScheme="dark">
      <Notifications />
      <NavigationProgress />
      <QueryClientProvider client={queryClient}>
        <ContextMenuProvider>
          <ModalsProvider>
            <GlobalSpotlight />
            <Router />
          </ModalsProvider>
        </ContextMenuProvider>
      </QueryClientProvider>
    </MantineProvider>
  );
}

export default function App() {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </CookiesProvider>
  );
}
