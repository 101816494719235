import { createContext, useContext, useState } from 'react';
import { ThemeName } from '@/config/themes.config';

type ThemeContextValue = {
  theme: ThemeName;
  setTheme: (value: ThemeName) => void;
};

const ThemeContext = createContext<ThemeContextValue>({
  theme: 'default',
  setTheme: () => {},
});

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const themeFromStorage: string = localStorage.getItem('theme') || 'default';
  const [theme, setTheme] = useState<ThemeName>(themeFromStorage as ThemeName);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
