import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import ErrorBoundary from '@/components/error/ErrorBoundary';
import { ProtectedRoute } from '@/components/utils/ProtectedRoute';
import { LoadingState } from '@/components/general/LoadingState';

// Layouts
const DefaultLayout = lazy(() =>
  import('@/components/layouts/default-layout/DefaultLayout').then((mod) => ({
    default: mod.DefaultLayout,
  }))
);
const ProtectedLayout = lazy(() =>
  import('@/components/layouts/protected-layout/ProtectedLayout').then((mod) => ({
    default: mod.ProtectedLayout,
  }))
);
const CampaignLayout = lazy(() =>
  import('@/components/layouts/campaign-layout/CampaignLayout').then((mod) => ({
    default: mod.CampaignLayout,
  }))
);

// Public pages
const HomePage = lazy(() =>
  import('@/pages/landing/home/Home.page').then((mod) => ({ default: mod.HomePage }))
);
const LoginPage = lazy(() => import('@/pages/login/Login.page'));
const PricingPage = lazy(() =>
  import('@/pages/landing/pricing/Pricing.page').then((mod) => ({ default: mod.PricingPage }))
);
const AboutUsPage = lazy(() =>
  import('@/pages/landing/about-us/AboutUs.page').then((mod) => ({ default: mod.AboutUsPage }))
);
const ContactUsPage = lazy(() =>
  import('@/pages/landing/contact-us/ContactUs.page').then((mod) => ({
    default: mod.ContactUsPage,
  }))
);
const ProfilePage = lazy(() =>
  import('@/pages/profile/home/Profile.page').then((mod) => ({ default: mod.ProfilePage }))
);

// Auth related pages
const AuthGroup = {
  ConfirmProfilePage: lazy(() => import('@/pages/profile/confirm/ConfirmProfile.page')),
  ForgotPassword: lazy(() =>
    import('@/components/auth/forgot-password/ForgotPassword').then((mod) => ({
      default: mod.ForgotPassword,
    }))
  ),
  AuthCallback: lazy(() =>
    import('@/components/auth/auth-callback/AuthCallback').then((mod) => ({
      default: mod.AuthCallback,
    }))
  ),
  CompleteProfilePage: lazy(() =>
    import('@/pages/profile/complete/CompleteProfile.page').then((mod) => ({
      default: mod.CompleteProfilePage,
    }))
  ),
};

// Campaign related pages
const CampaignGroup = {
  CampaignHomePage: lazy(() =>
    import('@/pages/campaign/home/CampaignHome.page').then((mod) => ({
      default: mod.default,
    }))
  ),
  CampaignQuestLogPage: lazy(() => import('@/pages/campaign/quests/CampaignQuestLog.page')),
  CampaignLibraryPage: lazy(() => import('@/pages/campaign/content/library/ContentLibrary.page')),
  CampaignSettingsPage: lazy(() => import('@/pages/campaign/settings/CampaignSettingsPage')),

  ContentCreationPage: lazy(() =>
    import('@/pages/campaign/content/create/CampaignContentCreation.page').then((mod) => ({
      default: mod.ContentCreationPage,
    }))
  ),
  ContentPage: lazy(() => import('@/pages/campaign/content/content/CampaignContent.page')),
  CampaignMapsPage: lazy(() =>
    import('@/pages/campaign/maps/CampaignMaps.page').then((mod) => ({
      default: mod.CampaignMapsPage,
    }))
  ),
  CampaignsListPage: lazy(() => import('@/pages/campaigns/list/CampaignListPage')),
  CampaignInvitePage: lazy(() =>
    import('@/pages/campaign/invite/CampaignInvite.page').then((mod) => ({
      default: mod.CampaignInvitePage,
    }))
  ),
};

const createRoutes = () =>
  createBrowserRouter([
    {
      path: '/',
      element: (
        <ErrorBoundary>
          <Suspense fallback={<LoadingState />}>
            <DefaultLayout />
          </Suspense>
        </ErrorBoundary>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<LoadingState />}>
              <HomePage />
            </Suspense>
          ),
        },
        {
          path: 'pricing',
          element: (
            <Suspense fallback={<LoadingState />}>
              <PricingPage />
            </Suspense>
          ),
        },
        {
          path: 'about',
          element: (
            <Suspense fallback={<LoadingState />}>
              <AboutUsPage />
            </Suspense>
          ),
        },
        {
          path: 'contact',
          element: (
            <Suspense fallback={<LoadingState />}>
              <ContactUsPage />
            </Suspense>
          ),
        },
        {
          path: 'login',
          element: (
            <Suspense fallback={<LoadingState />}>
              <LoginPage />
            </Suspense>
          ),
        },
        {
          path: 'profile',
          element: (
            <Suspense fallback={<LoadingState />}>
              <ProfilePage />
            </Suspense>
          ),
        },
        {
          path: 'auth/confirm',
          element: (
            <Suspense fallback={<LoadingState />}>
              <AuthGroup.ConfirmProfilePage />
            </Suspense>
          ),
        },
        {
          path: 'forgot-password',
          element: (
            <Suspense fallback={<LoadingState />}>
              <AuthGroup.ForgotPassword />
            </Suspense>
          ),
        },
        {
          path: 'auth/callback',
          element: (
            <Suspense fallback={<LoadingState />}>
              <AuthGroup.AuthCallback />
            </Suspense>
          ),
        },
        {
          path: 'complete-profile',
          element: (
            <Suspense fallback={<LoadingState />}>
              <AuthGroup.CompleteProfilePage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: '/',
      element: (
        <ErrorBoundary>
          <ProtectedRoute>
            <Suspense fallback={<LoadingState />}>
              <ProtectedLayout />
            </Suspense>
          </ProtectedRoute>
        </ErrorBoundary>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: 'campaigns',
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.CampaignsListPage openWithCreateModalOpen={false} />
            </Suspense>
          ),
        },
        {
          path: 'campaigns/new',
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.CampaignsListPage openWithCreateModalOpen />
            </Suspense>
          ),
        },
        {
          path: 'campaign/:campaignId/invite/:inviteId',
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.CampaignInvitePage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'campaign/:campaignId',
      element: (
        <ErrorBoundary>
          <Suspense fallback={<LoadingState />}>
            <CampaignLayout />
          </Suspense>
        </ErrorBoundary>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.CampaignHomePage />
            </Suspense>
          ),
        },
        {
          path: 'quests',
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.CampaignQuestLogPage />
            </Suspense>
          ),
        },
        {
          path: 'settings',
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.CampaignSettingsPage />
            </Suspense>
          ),
        },
        {
          path: 'library',
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.CampaignLibraryPage />
            </Suspense>
          ),
        },
        {
          path: 'content/create',
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.ContentCreationPage />
            </Suspense>
          ),
        },
        {
          path: 'content/:contentId',
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.ContentPage />
            </Suspense>
          ),
        },
        {
          path: 'maps',
          element: (
            <Suspense fallback={<LoadingState />}>
              <CampaignGroup.CampaignMapsPage />
            </Suspense>
          ),
        },
      ],
    },
  ]);

export function Router() {
  const router = createRoutes();

  return <RouterProvider router={router} />;
}
