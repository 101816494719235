import { createTheme, MantineThemeOverride } from '@mantine/core';

export const dungeonDelver = createTheme({
  autoContrast: true,
  colors: {
    'brand-dark-teal': [
      '#1B1D1F',
      '#2C2F33',
      '#40454A',
      '#586067',
      '#727E88',
      '#8B9BA3',
      '#A3B8BF',
      '#BBD4D9',
      '#D4F0F4',
      '#EFFFFF',
    ],
    'brand-orange': [
      '#4B1E00',
      '#662800',
      '#823300',
      '#9D3F00',
      '#B84B00',
      '#D3571A',
      '#F06333',
      '#FF7A5A',
      '#FF9981',
      '#FFD7BA',
    ],
    'brand-teal': [
      '#102224',
      '#1E3D3F',
      '#2D585B',
      '#3B7376',
      '#4A8E91',
      '#5EA9AC',
      '#72C5C8',
      '#8ADCE0',
      '#A2F2F5',
      '#BBFFFF',
    ],
    'footnote-text': [
      '#2E2C27',
      '#3C3A34',
      '#4A483F',
      '#58574B',
      '#666558',
      '#747265',
      '#828073',
      '#908F82',
      '#9E9E90',
      '#ACADA0',
    ],
    'brand-purple': [
      '#1C1020',
      '#2A1A2E',
      '#38223C',
      '#462A4A',
      '#543258',
      '#623B66',
      '#704474',
      '#7E4D82',
      '#8C5690',
      '#9A5F9E',
    ],
    accent: [
      '#5E0B2E',
      '#750E39',
      '#8D1245',
      '#A41650',
      '#B7195C',
      '#CC1D67',
      '#E12072',
      '#FF2480',
      '#FF4C9A',
      '#FF75B4',
    ],
    background: [
      '#0D0D0D',
      '#1B1B1B',
      '#292929',
      '#383838',
      '#474747',
      '#565656',
      '#666666',
      '#777777',
      '#888888',
      '#999999',
    ],
  },
  primaryColor: 'brand-orange',
} as MantineThemeOverride);
export const elvenGlade = createTheme({
  autoContrast: true,
  colors: {
    'brand-dark-teal': [
      '#0D3B27',
      '#14583D',
      '#1B7553',
      '#238D69',
      '#2BA580',
      '#34BD97',
      '#3CD6AD',
      '#54E0C7',
      '#6BEAD1',
      '#84F5DB',
    ],
    'brand-orange': [
      '#402200',
      '#5A3100',
      '#734000',
      '#8D5000',
      '#A66000',
      '#C07000',
      '#D98000',
      '#F19033',
      '#FFAA66',
      '#FFC499',
    ],
    'brand-teal': [
      '#e0faf4',
      '#c1f5e8',
      '#a1f0dc',
      '#82ebd1',
      '#62e6c5',
      '#43e1b9',
      '#23dcae',
      '#0cd7a2',
      '#00d296',
      '#00cc8a',
    ],
    'footnote-text': [
      '#fef7eb',
      '#f7ecdb',
      '#eee1cb',
      '#e5d6bb',
      '#dccbab',
      '#d3c09b',
      '#cbb48c',
      '#c2a97c',
      '#b99d6c',
      '#b0925c',
    ],
    'brand-purple': [
      '#1f0735',
      '#2d0b4a',
      '#3b0f60',
      '#4a1475',
      '#59178b',
      '#681ca0',
      '#7720b6',
      '#8723cd',
      '#9727e3',
      '#a72af9',
    ],
    accent: [
      '#224C3D',
      '#2D6455',
      '#37806D',
      '#42A085',
      '#4BC89E',
      '#54E0B6',
      '#5BF2C7',
      '#62FFD8',
      '#6CFFE1',
      '#75FFEB',
    ],
    background: [
      '#E9F6EE',
      '#D4EEDC',
      '#BED5C9',
      '#A8BFB6',
      '#94ABA3',
      '#809890',
      '#6C867E',
      '#59776C',
      '#466959',
      '#345B47',
    ],
  },
  primaryColor: 'brand-dark-teal',
} as MantineThemeOverride);

export const codeCraft = createTheme({
  autoContrast: true,
  colors: {
    'brand-dark-teal': [
      '#1E1E1E',
      '#252526',
      '#313233',
      '#3C3D3E',
      '#48494A',
      '#555657',
      '#646566',
      '#747576',
      '#858687',
      '#979899',
    ],
    'brand-orange': [
      '#C19C00',
      '#D4A800',
      '#E8B400',
      '#FFBF00',
      '#FFCA33',
      '#FFD566',
      '#FFE099',
      '#FFEBCC',
      '#FFF4E6',
      '#FFFFF2',
    ],
    'brand-teal': [
      '#002b2b',
      '#003d3d',
      '#005050',
      '#006363',
      '#007575',
      '#008888',
      '#009a9a',
      '#00adad',
      '#00bfbf',
      '#00d2d2',
    ],
    'footnote-text': [
      '#f2f2f2',
      '#e6e6e6',
      '#d9d9d9',
      '#cccccc',
      '#bfbfbf',
      '#b3b3b3',
      '#a6a6a6',
      '#999999',
      '#8c8c8c',
      '#808080',
    ],
    'brand-purple': [
      '#2e004f',
      '#3d0068',
      '#4c0082',
      '#5b009b',
      '#6a00b4',
      '#7a00ce',
      '#8900e7',
      '#9800ff',
      '#a64fff',
      '#b380ff',
    ],
    accent: [
      '#007ACC',
      '#0088FF',
      '#0094FF',
      '#00A1FF',
      '#00ADFF',
      '#00BAFF',
      '#00C7FF',
      '#00D4FF',
      '#00E1FF',
      '#00EEFF',
    ],
    background: [
      '#1E1E1E',
      '#252526',
      '#2C2C2C',
      '#333333',
      '#3A3A3A',
      '#414141',
      '#484848',
      '#4F4F4F',
      '#565656',
      '#5D5D5D',
    ],
  },
  primaryColor: 'brand-orange',
} as MantineThemeOverride);
export const cyberpunkGlow = createTheme({
  autoContrast: true,
  colors: {
    'brand-dark-teal': [
      '#0A001E',
      '#14003C',
      '#1E0059',
      '#280077',
      '#320096',
      '#3C00B4',
      '#4600D3',
      '#5000F1',
      '#6E33FF',
      '#8C66FF',
    ],
    'brand-orange': [
      '#FF0099',
      '#FF33A6',
      '#FF66B3',
      '#FF99C0',
      '#FFCCE6',
      '#FFDFFF',
      '#FFE5FF',
      '#FFEBFF',
      '#FFF2FF',
      '#FFF8FF',
    ],
    'brand-teal': [
      '#00f0ff',
      '#00e6f5',
      '#00dcf0',
      '#00d2ea',
      '#00c8e5',
      '#00bfdf',
      '#00b5da',
      '#00abd4',
      '#00a1cf',
      '#0097c9',
    ],
    'footnote-text': [
      '#f7f7f7',
      '#eeeeee',
      '#e6e6e6',
      '#dddddd',
      '#d5d5d5',
      '#cccccc',
      '#c4c4c4',
      '#bbbbbb',
      '#b3b3b3',
      '#aaaaaa',
    ],
    'brand-purple': [
      '#35004c',
      '#41005c',
      '#4e006d',
      '#5a007d',
      '#66008e',
      '#73009e',
      '#7f00af',
      '#8c00bf',
      '#9900d0',
      '#a600e0',
    ],
    accent: [
      '#00FF66',
      '#33FF80',
      '#66FF99',
      '#99FFB3',
      '#CCFFCC',
      '#DFFFDF',
      '#E5FFE5',
      '#EBFFEB',
      '#F2FFF2',
      '#F8FFF8',
    ],
    background: [
      '#030303',
      '#0D0D0D',
      '#171717',
      '#222222',
      '#2C2C2C',
      '#363636',
      '#404040',
      '#4A4A4A',
      '#545454',
      '#5E5E5E',
    ],
  },
  primaryColor: 'accent',
} as MantineThemeOverride);

export const arcaneScholar = createTheme({
  autoContrast: true,
  colors: {
    'brand-dark-teal': [
      '#2C1A29',
      '#3A2437',
      '#482E45',
      '#573953',
      '#654361',
      '#73506F',
      '#81637E',
      '#91778E',
      '#A18C9E',
      '#B1A1AF',
    ],
    'brand-orange': [
      '#59462D',
      '#705635',
      '#88663D',
      '#A07845',
      '#B88A4E',
      '#D09B56',
      '#E8AD5E',
      '#FFBE66',
      '#FFD499',
      '#FFEACC',
    ],
    accent: [
      '#0E2E5E',
      '#123C7E',
      '#17499E',
      '#1B57BF',
      '#1F65DF',
      '#2373FF',
      '#4A8CFF',
      '#719DFF',
      '#99ADFF',
      '#C0BEFF',
    ],
    'brand-teal': [
      '#0D2B2B',
      '#133D3D',
      '#1A5050',
      '#206363',
      '#276676',
      '#2E7A7A',
      '#358D8D',
      '#3CA1A1',
      '#42B4B4',
      '#49C8C8',
    ],
    'footnote-text': [
      '#F9F7F3',
      '#F3EFE7',
      '#ECE7DB',
      '#E6E0D0',
      '#DFD8C5',
      '#D9D0B9',
      '#D2C9AE',
      '#CCC2A3',
      '#C5BA97',
      '#BFB38C',
    ],
    'brand-purple': [
      '#1F0E3B',
      '#2C1451',
      '#391A66',
      '#46207C',
      '#532692',
      '#602CA7',
      '#6D32BD',
      '#7A38D3',
      '#8740E9',
      '#9457FF',
    ],

    background: [
      '#F5F1EC',
      '#E8E0D9',
      '#D8CBC2',
      '#C6B4A8',
      '#B49C8C',
      '#A58677',
      '#967160',
      '#865C4A',
      '#764835',
      '#653521',
    ],
  },
  primaryColor: 'accent',
} as MantineThemeOverride);

export const defaultTheme = createTheme({
  autoContrast: true,
  colors: {
    'brand-dark-teal': [
      '#f3f6f7',
      '#e8e9e9',
      '#cbd0d2',
      '#abb8bc',
      '#91a3a9',
      '#7f959d',
      '#758f99',
      '#637b85',
      '#556e77',
      '#43606a',
    ],
    accent: [
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
    ],
    'brand-teal': [
      '#e3fef8',
      '#d2f8f0',
      '#abecdf',
      '#81e0cd',
      '#5dd7bd',
      '#46d1b4',
      '#36ceaf',
      '#23b799',
      '#0fa387',
      '#008d74',
    ],
    'brand-orange': [
      '#fff1e3',
      '#fee1d1',
      '#f7c2a3',
      '#f2a172',
      '#ec8448',
      '#ea722d',
      '#ea691f',
      '#d05712',
      '#ba4d0d',
      '#a24003',
    ],
    'brand-purple': [
      '#f3f0f7',
      '#e8def0',
      '#dccbe8',
      '#d1b9e1',
      '#c5a6d9',
      '#b894d1',
      '#ac81c9',
      '#a06ec1',
      '#945ab9',
      '#8848b1',
    ],
    'footnote-text': [
      '#fef7e5',
      '#f4ecd7',
      '#e6d7b3',
      '#d6c18d',
      '#c9ae6b',
      '#c2a255',
      '#be9c4a',
      '#a88739',
      '#957830',
      '#816722',
    ],
    background: [
      '#f5f5f5',
      '#e7e7e7',
      '#cdcdcd',
      '#b2b2b2',
      '#9a9a9a',
      '#8b8b8b',
      '#848484',
      '#717171',
      '#656565',
      '#575757',
    ],
  },
  primaryColor: 'brand-dark-teal',
} as MantineThemeOverride);

export type ThemeName =
  | 'default'
  | 'dungeonDelver'
  | 'elvenGlade'
  | 'codeCraft'
  | 'cyberpunkGlow'
  | 'arcaneScholar';

export const themes: Record<ThemeName, MantineThemeOverride> = {
  default: defaultTheme,
  dungeonDelver,
  elvenGlade,
  codeCraft,
  cyberpunkGlow,
  arcaneScholar,
};

export const themesList = [
  {
    value: 'default',
    label: 'Default',
    preview: ['#43606a', '#5dd7bd', '#ec8448'],
  },
  {
    value: 'dungeonDelver',
    label: 'Dungeon Delver',
    preview: ['#1B1D1F', '#D3571A', '#8B9BA3'],
  },
  {
    value: 'elvenGlade',
    label: 'Elven Glade',
    preview: ['#0D3B27', '#2BA580', '#A66000'],
  },
  {
    value: 'codeCraft',
    label: 'Code Craft',
    preview: ['#1E1E1E', '#C19C00', '#007ACC'],
  },
  {
    value: 'cyberpunkGlow',
    label: 'Cyberpunk Glow',
    preview: ['#280077', '#FF0099', '#00FF66'],
  },
  {
    value: 'arcaneScholar',
    label: 'Arcane Scholar',
    preview: ['#2C1A29', '#E8AD5E', '#0E2E5E'],
  },
];
